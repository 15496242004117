<template lang="pug">
  main#main.account-logout.d-flex.flex-grow-1.justify-content-center
    b-container
      b-row
        b-col.mx-auto(sm='12' md='10' lg='8' xl='6')
          section.l-section
            .l-section-h
              b-card.card-transparent
                .pb-5.text-center
                  .wc-i.wc-symbol.rounded(style='width: 80px; height: 80px')
                  .h4.m-0.mt-2.text-uppercase.text-white
                    | {{ $wc.conf.base.name }}

                .text-center.mb-4
                  h5
                    | {{ $t('session.out.t1') }}
                    br
                    | {{ $t('session.out.t2') }}

                .d-flex.justify-content-center
                  wc-loaders-cube
</template>

<script>
import WcLoadersCube from '@components/shared/loaders/WcLoadersCube'
import apiAccount from '@services/api/account'

export default {
  name: 'account-logout',
  components: {
    WcLoadersCube,
  },
  methods: {
    logoutSuccess() {
      setTimeout(() => {
        this.$store.dispatch('auth/logout')
        this.$route.query.post_logout_redirect_uri
          ? window.location.replace(this.$route.query.post_logout_redirect_uri)
          : this.$router.replace(this.$route.query.redirect || { name: 'login' })
      }, 1500)
    },
    logoutError() {},
  },
  mounted() {
    /* Only on client side. */
    apiAccount
      .logout(this.$http)
      .promise.then(() => this.logoutSuccess())
      .catch(() => this.logoutError())
  },
}
</script>
