<template lang="pug">
  layout-access
    account-logout
</template>

<script>
import LayoutAccess from '@components/layouts/Access'
import AccountLogout from '@views/container/account/Logout'

export default {
  name: 'logout',
  components: {
    LayoutAccess,
    AccountLogout,
  },
}
</script>
